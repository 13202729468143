import { LoginParams, RegisterParams, RegisterResponse, RequestPasswordResetParams, ResetPasswordParams, UserEditParams, UserSettings } from '~/types/database/user'
import { supabase } from '~/services/supabase'
import { createResource } from 'solid-js'
import { getContacts } from '~/services/database/contacts'
import { APIError } from '~/types/api/errors'

const registerUser = async (payload: RegisterParams) => {
  const result = await fetch('/api/user/register', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
  const response: RegisterResponse & APIError = await result.json()
  return response
}

const initUser = async ({ access_token, refresh_token }: RegisterResponse) => {
  return await supabase.auth.setSession({
    access_token,
    refresh_token
  })
}

const loginUser = async ({ email, password }: LoginParams) => {
  const loginResponse = await supabase.auth.signInWithPassword({
    email,
    password
  })
  refetchUser()
  return loginResponse
}

const requestPasswordReset = async ({ email }: RequestPasswordResetParams) => {
  const redirectTo = `${window.location.origin}/account/reset-password`
  return await supabase.auth.resetPasswordForEmail(email, {
    redirectTo
  })
}

const resetPassword = async ({ password }: ResetPasswordParams) => {
  return await supabase.auth.updateUser({
    password: password
  })
}

const getUser = async () => {
  const user = (await supabase.auth.getUser()).data.user
  if(user){
    const { data } = await supabase
      .from('users')
      .select()
      .eq('id', user.id)
      .single()
    return {
      ...data,
      email: user.email,
    }
  }else{
    return null
  }
}

const getUserSettings = async () => {
  const dbValue = (await getUser())?.settings ?? null
  const settings: UserSettings | null = dbValue ? JSON.parse(dbValue) : null
  return settings
}

const editUserSettings = async (payload: UserSettings) => {
  const currentSettings = await getUserSettings()
  const settings = JSON.stringify({
    ...currentSettings,
    ...payload
  })
  editUser({
    settings
  })
}

const getUsers = async () => {
  const user = (await supabase.auth.getUser()).data.user
  const contactIds = (await getContacts()).map(contact => contact.contact_id)
  if(user){
    const { data } = await supabase
      .from('users')
      .select()
      .neq('id', user.id)
    const availableUsers = data?.filter(entry => !contactIds.includes(entry.id))
    return availableUsers
  }else{
    return []
  }
}

const editUser = async (payload: UserEditParams) => {
  const user = (await supabase.auth.getUser()).data.user
  if(user){
    const { data } = await supabase
      .from('users')
      .update(payload)
      .eq('id', user.id)

    await refetchUser()      
    return data
  }
}

const [user, { refetch: refetchUser }] = createResource(getUser)

export {
  registerUser,
  initUser,
  loginUser,
  requestPasswordReset,
  resetPassword,
  getUser,
  getUserSettings,
  editUserSettings,
  getUsers,
  editUser,
  refetchUser,
  user
}